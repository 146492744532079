// import Parser from "../network/parser.js";

export default class ContentType {
	constructor(rawData = {}) {
		this.id = rawData.id;
		this.code = rawData.code;
		this.type = rawData.type;
		this.menuTitle = rawData.menuTitle;
		this.customTitleConfig1 = rawData.customTitleConfig1;
		this.customTitleConfig2 = rawData.customTitleConfig2;
		this.customDescriptionConfig = rawData.customDescriptionConfig;
		this.description = rawData.description;
		this.postCodeConfig = rawData.postCodeConfig;
		this.titleConfig = rawData.titleConfig;
		this.shortDescriptionConfig = rawData.shortDescriptionConfig;
		this.htmlDescriptionConfig = rawData.htmlDescriptionConfig;
		this.fullDescriptionConfig = rawData.fullDescriptionConfig;
		this.postDateConfig = rawData.postDateConfig;
		this.noOfAllowedPosts = rawData.noOfAllowedPosts;
		this.contentCategory = rawData.contentCategory;
		this.maxUploadFiles = rawData.maxUploadFiles;
		this.maxUploadImages = rawData.maxUploadImages;
		this.contentType = rawData.contentType
			? new ContentType(rawData.contentType)
			: null;
		this.parentId = rawData.parentId;
		this.contentTypeCategories = rawData.contentTypeCategories;
		this.contentTypeCategories = rawData.contentTypeCategories;
		// ? Parser.fromJson('ContentTypeCategory', rawData.contentTypeCategories)
		// : [];
		this.parentId = rawData.parentId;
		this.isActive = rawData.isActive;
		this.orderNumber = rawData.orderNumber;
		this.optionalFeatureContentTypes = rawData.optionalFeatureContentTypes;
		this.desktopImageDescriptionConfig = rawData.desktopImageDescriptionConfig;
		this.tabletImageDescriptionConfig = rawData.tabletImageDescriptionConfig;
		this.mobileImageDescriptionConfig = rawData.mobileImageDescriptionConfig;
		this.updateSeoCode = rawData.updateSeoCode
	}
}
