<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="digitalMarketingConfigHeaders"
          :items="digitalMarketingConfig"
          class="capitalize large"
        >
          <template v-slot:item.isActive="{ item }">
            <v-icon color="green" v-if="item.isActive">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon color="red" v-else> mdi-close-circle </v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              v-if="!item.isActive"
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-check
            </v-icon>
            <v-icon
              small
              v-if="item.isActive"
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      snackbarText: ``,
      snackbar: false,
      digitalMarketingConfigHeaders: [
        {
          text: "isActive",
          align: "start",
          sortable: false,
          value: "isActive",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editPostFileClickDialog: false,
      editPostFileClick: null,
      // editedPostFileClick: new PostFileClick(),
    };
  },
  async mounted() {
    await this.loadDigitalMarketingConfig();
  },

  computed: {
    ...mapGetters("contentManagement", ["digitalMarketingConfig"]),
  },
  methods: {
    async loadDigitalMarketingConfig() {
      await this.$store.dispatch("contentManagement/ladDigitalMarketingConfig");
    },
    async changeActiveStatus(item) {
      item.isActive = !item.isActive
      await this.$store.dispatch(
        "contentManagement/updateDigitalMarketing",
        item
      );
    },
  },
};
</script>
<style lang="scss">
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.table-titles {
  font-size: 16px;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: large;
  color: black;
}
</style>
