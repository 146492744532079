import { PostFileClick } from "../models/index";

export default class Parser {
  static parse(type, data) {
    if (!data) return "success"    ;
    switch (type) {
      case "PostFileClick":
        return this.fromJson(PostFileClick, data);
      default:
        return data;
    }
  }
  static fromJson(type, data) {
    if (data.constructor === Array) {
      const result = [];
      data.forEach((element) => {
        result.push(new type(element));
      });
      return result;
    } else return new type(data);
  }
}
