<template>
  <v-container fluid>
    <v-container fluid>
      <v-row>
        <v-dialog v-model="digitalMarketingDialog" max-width="850px">
          <template v-slot:activator="{ on, attrs }">
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="mb-6 custom-button mt-4"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-plus</v-icon>
              New Digital Marketing
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Digital Marketing</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="editedDigitalMarketing.type"
                        label="type"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="editedDigitalMarketing.code"
                        label="code"
                        :rules="required"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                class="custom-button"
                @click="closeDigitalMarketing"
                text
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1 white--text"
                class="custom-button"
                :disabled="!valid"
                @click="submitDigitalMarketing"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <v-col>
      <v-data-table
        :headers="digitalMarketingHeaders"
        :items="digitalMarketing"
        hide-default-footer
        class="capitalize"
      >
      </v-data-table>
    </v-col>
    <v-snackbar v-model="snackbar">
      {{ snakbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import digitalMarketing from "../../models/digital-marketing";

export default {
  name: "ContentCategoriesListing",
  data() {
    return {
      digitalMarketingHeaders: [
        {
          text: "Type",
          value: "type",
        },

        {
          text: "code",
          align: "center",
          sortable: false,
          value: "code",
        },
      ],
      digitalMarketingDialog: false,
      editedDigitalMarketing: new digitalMarketing(),
      required: [(v) => !!v || "this field is required"],
      valid: false,
      snackbar:false,
      snakbarText:''
    };
  },
  async mounted() {
    await this.loadDigitalMarketing();
  },
  computed: {
    ...mapGetters("digitalMarketing", ["digitalMarketing"]),
  },
  methods: {
    async loadDigitalMarketing() {
      await this.$store.dispatch("digitalMarketing/loadDigitalMarketing");
    },
    async submitDigitalMarketing() {
      try {
        await this.$store.dispatch(
          "digitalMarketing/createDigitalMarketing",
          this.editedDigitalMarketing
        );
        this.snakbarText = "digital marketing added successfully";
      } catch (error) {
        this.snakbarText = error;
      }finally {
        this.snackbar = true;
        this.closeDigitalMarketing();

      }
    },
    closeDigitalMarketing() {
      this.digitalMarketingDialog = false;
    },
  },
};
</script>
<style scoped>
.custom-button {
  border-radius: 3.125rem;
}

.featured {
  display: flex;
  justify-content: center;
}
.v-data-table {
  font-size: large;
  color: black;
}
.img-category {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
