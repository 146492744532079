import NetworkManager from "../../../network/network-manager.js";
import Parser from "../network/parser.js";

export default {
  async loadLanguages(context) {
    await NetworkManager.get(
      context,
      `language/activestatus/true`,
      "Language",
      "setLanguages",
      Parser
    );
  },
  async loadSupportedLanguages(context) {
    await NetworkManager.get(
      context,
      `supported-language`,
      "Language",
      "setSupportedLanguages",
      Parser
    );
  },

  async addLanguage(context, payload) {
    await NetworkManager.post(
      context,
      `language`,
      payload,
      "Language",
      null,
      Parser
    );
  },

  async deactivateLanguage(context, payload) {
    await NetworkManager.put(
      context,
      `language/${payload.id}`,
      null,
      "Language",
      "deleteLanguage",
      Parser
    );
  },

  async updateDefaultLanguage(context, payload) {
    await NetworkManager.put(
      context,
      `language/set-default/${payload}`,
      "Language",
      null,
      null,
      Parser
    );
  },
  async updateMenuCategory(context, payload) {
    await NetworkManager.put(
      context,
      `menu-category/${payload.id} `,
      payload,
      "MenuCategory",
      null,
      Parser
    );
  },
  async updateMenuCategoryChildren(context, payload) {
    await NetworkManager.put(
      context,
      `menu-category/assign-parent-id/${payload.parent.id} `,
      payload.children,
      "MenuCategory",
      null,
      Parser
    );
  },

  async loadContentTypes(context) {
    await NetworkManager.get(
      context,
      `content-type`,
      "ContentType",
      "setContentTypes",
      Parser
    );
  },

  async loadContentTypesWithMenuCategoryNull(context) {
    await NetworkManager.get(
      context,
      `content-type/empty-menu-category`,
      "ContentType",
      "setContentTypeWithMenuCategoryNull",
      Parser
    );
  },

  async loadContentCategories(context) {
    const locale = context.getters.defaultLanguage.locale;
    await NetworkManager.get(
      context,
      `content-category/language/${locale}`,
      "ContentCategory",
      "setContentCategories",
      Parser
    );
  },
  async loadContentTypeCategoryByDefaultLanguage(context) {
    const locale = context.getters.defaultLanguage.locale;
    await NetworkManager.get(
      context,
      `localized-category/language/${locale}`,
      "LocalizedContentCategory",
      "setLocalizedContentTypesCategories",
      Parser
    );
  },

  async loadContentTypeCategoryByContentTypeId(context, payload) {
    await NetworkManager.get(
      context,
      `localized-category/content-type/${payload}`,
      "LocalizedContentCategory",
      "setLocalizedContentTypesCategories",
      Parser
    );
  },

  async loadContentTypeCategoryByCategoryId(context, payload) {
    await NetworkManager.get(
      context,
      `localized-category/category/${payload}`,
      "LocalizedContentCategory",
      "setLocalizedContentTypesCategories",
      Parser
    );
  },

  async loadContentTypeCategoryByContentTypeIdAndDefaultLanguage(
    context,
    payload
  ) {
    if (context.state.defaultLanguage == null)
      await context.dispatch("loadLanguages");

    await NetworkManager.get(
      context,
      `localized-category/content-type/${payload.id}/language/${context.getters.defaultLanguage.locale}/active-status/${payload.isActive}`,
      "LocalizedContentCategory",
      "setContentTypeCategory",
      Parser
    );
  },

  async findCategoryById(context, payload) {
    return await NetworkManager.get(
      context,
      `localized-category/category/${payload}`,
      "LocalizedContentCategory",
      null,
      Parser
    );
  },

  async addContentCategory(context, payload) {
    return await NetworkManager.post(
      context,
      `content-category`,
      payload,
      "ContentCategory",
      "addContentCategory",
      Parser
    );
  },
  async updateContentCategoryImage(context, payload) {
    return await NetworkManager.put(
      context,
      `/content-category/upload-img/${payload.contentCategoryId}`,
      payload.formData,
      "ContentCategory",
      "setImageUrl",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  async updateContentCategoryStatus(context, payload) {
    await NetworkManager.put(
      context,
      `content-category/active-status`,
      payload,
      "ContentCategory",
      "updateContentCategoryStatus",
      Parser
    );
  },

  async updateContentTypeCategories(context, payload) {
    let response = await NetworkManager.put(
      context,
      `content-type-category/content-type/${
        payload.contentType?.id
          ? payload.contentType.id
          : payload.contentTypeCategories[0].contentType.id
      }`,
      payload.contentTypeCategories,
      "ContentTypeCategory",
      "updateContentTypeCategories",
      Parser
    );
    return response;
  },

  async addLocalizedContentCategory(context, payload) {
    await NetworkManager.post(
      context,
      `localized-category`,
      payload,
      "LocalizedContentCategory",
      "addLocalizedContentCategory",
      Parser
    );
  },

  async updateLocalizedContentCategory(context, payload) {
    await NetworkManager.put(
      context,
      `localized-category/${payload.id}`,
      payload,
      "LocalizedContentCategory",
      "updateLocalizedContentCategory",
      Parser
    );
  },
  async addContentCategoryImage(context, payload){
    return await NetworkManager.post(
      context,
      `content-category-file`,
      payload,
      "ContentCategoryFile",
      "addContentCategoryFile",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  async updateContentCategoryImagesTypes(context, payload){
    await NetworkManager.put(
      context,
      `content-category-file-image-type/content-category-file/${payload.contentCategoryFileId}`,
      payload.imagesTypes,
      "ContentCategoryFileImageType",
      payload.mutation,
      Parser
    );
  },

  async removeContentCategoryFile(context, payload){
    await NetworkManager.put(
      context,
      `content-category-file/${payload}`,
      null,
      "ContentCategoryFile",
      "removeContentCategoryFile",
      Parser
    );
  },


  async addContentType(context, payload) {
    return await NetworkManager.post(
      context,
      `content-type`,
      payload,
      "ContentType",
      "addContentType",
      Parser
    );
  },
  async updateContentType(context, payload) {
    await NetworkManager.put(
      context,
      `content-type`,
      payload,
      "ContentType",
      "updateContentType",
      Parser
    );
  },

  async updateContentTypeStatus(context, payload) {
    await NetworkManager.put(
      context,
      `content-type/active-status`,
      payload,
      "ContentType",
      "updateContentTypeStatus",
      Parser
    );
  },

  async updateContentCategory(context, payload) {
    await NetworkManager.put(
      context,
      `content-category/${payload.id}`,
      payload,
      "ContentTypeCategory",
      null,
      Parser
    );
  },

  async loadMenuCategory(context) {
    return await NetworkManager.get(
      context,
      `menu-category/find-all-sorted`,
      "MenuCategory",
      "setMenuCategory",
      Parser
    );
  },
  async loadOptionalFeaturesById(context, payload) {
    return await NetworkManager.get(
      context,
      `optional-feature-content-type/content-type/${payload}`,
      "ContentTypeOptionalFeature",
      "setEditedContentTypeOptionalFeatures",
      Parser
    );
  },
  async loadOptionalFeatures(context) {
    await NetworkManager.get(
      context,
      `optional-feature`,
      "OptionalFeature",
      "setOptionalFeature",
      Parser
    );
  },
  async addOptionalFeature(context, payload) {
    await NetworkManager.post(
      context,
      `optional-feature`,
      payload,
      "OptionalFeature",
      "addOptionalFeature",
      Parser
    );
  },
  async updateContentTypeOptionalFeatures(context, payload) {
    await NetworkManager.put(
      context,
      `optional-feature-content-type/content-type/${payload.contentTypeId}`,
      payload.optionalFeaturesArray,
      "OptionalFeature",
      null,
      Parser
    );
  },
  async getContentTypeById(context, payload) {
    await NetworkManager.get(
      context,
      `content-type/${payload}`,
      "ContentType",
      "setContentTypeById",
      Parser
    );
  },
  async addContentTypeToMenuCategory(context, payload) {
    const res = await NetworkManager.put(
      context,
      `menu-category/assign-content-type-to-menu-category/${payload.id}`,
      payload.contentTypeArray,
      "MenuCategory",
      "setContentTypeToMenuCategory",
      Parser
    );
    context.state.chnageCategory = res;
  },
  async addMenuCategory(context, payload) {
    await NetworkManager.post(
      context,
      `menu-category`,
      payload,
      "MenuCategory",
      "addMenuCategory",
      Parser
    );
  },
  async updateOrder(context, payload) {
    return await NetworkManager.put(
      context,
      `content-type/${payload.contentTypeId}/order?orderNumber=${payload.orderNumber}`,
      null,
      "ContentType",
      null,
      Parser
    );
  },
  async updateOrderFirstTime(context, payload) {
    return await NetworkManager.put(
      context,
      `content-type/order`,
      payload,
      "ContentType",
      "setOrderNumber",
      Parser
    );
  },
  async updateContentCategoryOrder(context, payload) {
    return await NetworkManager.put(
      context,
      `content-category/${payload.contentCategoryId}/order?orderNumber=${payload.orderNumber}`,
      null,
      "ContentCategory",
      "setContentCategoryOrderNumber",
      Parser
    );
  },
  async updateContentCategoryOrderFirstTime(context, payload) {
    return await NetworkManager.put(
      context,
      `content-category/order`,
      payload,
      "ContentCategory",
      "setContentCategoryOrderNumber",
      Parser
    );
  },

  //contactus lookups
  async loadLookupType(context){
    await NetworkManager.get(
      context,
      `lookup-type`,
      "LookupType",
      "setLookupType",
      Parser
    );
  },
  async loadLookupData(context){
    await NetworkManager.get(
      context,
      `lookup-data`,
      "LookupData",
      "setLookupData",
      Parser
    );

  },
  async createLookupType(context,payload){
    await NetworkManager.post(
      context,
      `lookup-type`,
      payload,
      "LookupType",
      "createLookupType",
      Parser
    );
  },
  async updateLookupType(context,payload){
    await NetworkManager.put(
      context,
      `lookup-type/${payload.id}`,
      payload,
      "LookupType",
      null,
      Parser
    );
  },
  async createLookupData(context,payload){
    await NetworkManager.post(
      context,
      `lookup-data`,
      payload,
      "LookupData",
      null,
      Parser
    );
  },
  async updateLookupData(context,payload){
    await NetworkManager.put(
      context,
      `lookup-data/${payload.id}`,
      payload,
      "LookupData",
      null,
      Parser
    );
  },
  
  // cache actions
  async loadCacheConfigs(context) {
    await NetworkManager.get(
      context,
      `cache/core-config`,
      "CoreConfig",
      "setCacheConfigs",
      Parser
    );
  },
  async purgeTenantCache(context) {
    await NetworkManager.delete(
      context,
      `cache/purge-all-tenant-keys`,
      null,
      "cachePurgeStatus",
      "setCachePurgeStatus",
      Parser
    );
  },
  async purgeCacheByPattern(context, payload) {
    await NetworkManager.delete(
      context,
      `cache/purge-by-pattern/${payload}`,
      null,
      "cachePurgeStatus",
      "setCachePurgeStatus",
      Parser
    );
  },
  async updateCacheConfig(context, payload) {
    await NetworkManager.put(
      context,
      `cache/core-config/${payload.configKey}/${payload.configValue}`,
      null,
      "CoreConfig",
      null,
      Parser
    );
  },

  //digital marketing 
  async ladDigitalMarketingConfig(context) {
    await NetworkManager.get(
      context,
      `/analytics-config`,
      "DigitalMarketingConfig",
      "setDigitalMrketingConfig",
      Parser
    );
  },

  async updateDigitalMarketing(context,payload){
    await NetworkManager.put(
      context,
      `/analytics-config`,
      payload,
      "DigitalMarketingConfig",
      null,
      Parser
    );
  }


};
