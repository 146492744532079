<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="postFileClickHeaders"
          :items="postsFilesClicks"
          class="capitalize large"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="editName(item)"> mdi-pencil </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="editPostFileClickDialog" max-width="850px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Edit Name</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="contactUsColumnsForm">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedPostFileClick.name"
                              class="disabled-name"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closePostFileClickDialog"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import PostFileClick from "../../models/post-file-click";

export default {
  data() {
    return {
      snackbarText: ``,
      snackbar: false,
      postFileClickHeaders: [
        {
          text: "url",
          align: "start",
          sortable: false,
          value: "postFileUrl",
        },
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "numbers of click",
          align: "start",
          sortable: false,
          value: "countOfClicked",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editPostFileClickDialog: false,
      editPostFileClick: null,
      editedPostFileClick: new PostFileClick(),
    };
  },
  async mounted() {
    await this.loadPostsFilesClicks();
  },

  computed: {
    ...mapGetters("postFileClick", ["postsFilesClicks"]),
  },
  methods: {
    async loadPostsFilesClicks() {
      await this.$store.dispatch("postFileClick/loadPostsFilesClicks");
    },
    editName(item) {
      this.editPostFileClickDialog = true;
      this.editedPostFileClick = item;
    },
    closePostFileClickDialog() {
      this.editPostFileClickDialog = false;
    },
    async submitData() {
      try {
        await this.$store.dispatch(
          "postFileClick/updatePostFileClickName",
          this.editedPostFileClick
        );
        this.snackbar = true;
        this.snackbarText = "Update Name Successfully";
        this.closePostFileClickDialog();
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
  },
};
</script>
<style lang="scss">
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.table-titles {
  font-size: 16px;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: large;
  color: black;
}
</style>
