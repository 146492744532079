import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";
export default {
  async loadDigitalMarketing(context) {
    await NetworkManager.get(
      context,
      `analytics`,
      "DigitalMarketing",
      "setDigitalMarketing",
      Parser
    );
  },
  async createDigitalMarketing(context,payload){
    await NetworkManager.post(
      context,
      `analytics`,
      payload,
      "DigitalMarketing",
      "createDigitalMarketing",
      Parser
    );
  },
};
