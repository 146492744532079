import { listToTree } from "../../../plugins/build-tree";
export default {
  setLanguages(state, payload) {
    state.languages = payload;
    state.defaultLanguage = payload.find(
      (element) => element.isDefault === true
    );
  },
  setSupportedLanguages(state, payload) {
    state.supportedLanguages = payload;
  },
  deleteLanguage(state, payload) {
    let index = state.languages.findIndex((x) => x.locale == payload.locale);
    state.languages.splice(index, 1);
  },
  setContentTypes(state, payload) {
    state.contentTypes = payload;
    state.contentTypesTrees = listToTree([...state.contentTypes]);
  },
  setContentCategories(state, payload) {
    state.contentCategories = payload;
  },
  setContentTypeCategory(state, payload) {
    state.contentTypesCategories = payload;
  },
  setLocalizedContentTypesCategories(state, payload) {
    state.localizedContentTypesCategories = payload;
  },
  addContentType(state, payload) {
    state.newlyAddedContentType = payload;
    state.contentTypes.push({
      ...payload,
      parentId: state.newlyAddedContentTypeParent?.id,
    });
    state.contentTypeWithMenuCategoryNull.push(payload);
    state.contentTypesTrees = listToTree([...state.contentTypes]);
  },

  updateContentType(state, payload) {
    state.newlyAddedContentType = payload;
  },

  setNewlyAddedContentTypeParent(state, payload) {
    state.newlyAddedContentTypeParent = payload;
  },
  updateContentTypeCategories(state, payload) {
    if (payload.length > 0) {
      let contentTypeId = payload[0].contentType.id;
      let contentType = state.contentTypes.find((a) => a.id == contentTypeId);
      contentType.contentTypeCategories = payload;
    }
    state.contentTypes = state.contentTypes.map((contentType) => {
      if (contentType.id === state.newlyAddedContentType?.id) {
        return { ...contentType, contentTypeCategories: payload };
      }
      return contentType;
    });
    state.contentTypesTrees = listToTree([...state.contentTypes]);
  },
  // TODO: MULTIPLE LEVELS UPDATE
  updateContentTypeStatus(state, payload) {
    state.contentTypes = state.contentTypes.map((contentType) => {
      if (contentType.id === payload.id) return payload;
      else if (contentType.parentId === payload.id)
        return {
          ...contentType,
          contentType: { ...payload },
          isActive: payload.isActive,
        };
      return contentType;
    });
    state.contentTypesTrees = listToTree([...state.contentTypes]);
  },
  setContentTypeById(state, payload) {
    state.contentTypeById = payload;
  },
  setMenuCategory(state, payload) {
    state.menuCategory = payload;
  },
  addMenuCategory(state, payload) {
    state.menuCategory.push(payload);
  },
  setContentTypeWithMenuCategoryNull(state, payload) {
    state.contentTypeWithMenuCategoryNull = payload;
  },
  setNewlyAddedContentCategoryParent(state, payload) {
    state.newlyAddedContentCategoryParent = payload;
  },
  setNewlyAddedRelatedToParent(state, payload) {
    state.newlyAddedRelatedToParent = payload;
  },
  addContentCategory(state, payload) {
    this.contentCategoryAdded = payload;
    state.contentCategories.push({
      ...payload,
      parentId: state.newlyAddedContentCategoryParent?.id,
      relatedToParentId: state.newlyAddedRelatedToParent?.id,
    });
  },
  setImageUrl(state, payload) {
    let contentCategorySelected = state.contentCategories.find(
      (x) => x.id == payload.id
    );
    contentCategorySelected.imgUrl = payload.imgUrl;
  },
  addContentCategoryFile(state, payload) {
    state.contentCategoryFile = payload;
  },
  updateImageType(state , payload){
    let contentCategory = state.contentCategories.find(
      (a) => a.id == payload.contentCategoryId
    );
    let file = contentCategory.contentCategoryFiles.find(a => a.id == payload.id);
    file.contentCategoryFileImageTypes = payload.contentCategoryFileImageTypes
  },
  addContentCategoryImagesTypes(state, payload) {
    let contentCategory = state.contentCategories.find(
      (a) => a.id == payload.contentCategoryId
    );
    contentCategory.contentCategoryFiles
      ? contentCategory.contentCategoryFiles.push(payload)
      : (contentCategory.contentCategoryFiles = [payload]);
  },
  removeContentCategoryFile(state, payload) {
    let contentCategory = state.contentCategories.find(
      (a) => a.id == payload.contentCategoryId
    );
   let contentCategoryFileIndex= contentCategory.contentCategoryFiles.findIndex(a => a.id == payload.id);
    contentCategory.contentCategoryFiles.splice(contentCategoryFileIndex, 1);
  },
  updateContentCategoryStatus(state, payload) {
    state.contentCategories = state.contentCategories.map((contentCategory) => {
      if (contentCategory.id === payload.id)
        return {
          ...payload,
          localizations: contentCategory.localizations,
        };
      else if (contentCategory.parentId === payload.id)
        return {
          ...contentCategory,
          isActive: payload.isActive,
        };
      return contentCategory;
    });
  },
  addLocalizedContentCategory(state, payload) {
    const locale = state.defaultLanguage.locale;
    if (payload.locale === locale) {
      state.contentCategories = state.contentCategories.map(
        (contentCategory) => {
          if (contentCategory.id === payload.contentCategoryId) {
            return {
              ...contentCategory,
              localizations: { [payload.locale]: payload },
            };
          }
          return contentCategory;
        }
      );
    }
  },
  setContentTypeToMenuCategory(state, payload) {
    const meneuCategoryChanged = state.menuCategory.find(
      (a) => a.id == payload.id
    );
    meneuCategoryChanged.contentTypeList = payload.contentTypeList;
  },
  updateLocalizedContentCategory(state, payload) {
    const locale = state.defaultLanguage.locale;
    state.contentCategories = state.contentCategories.map((contentCategory) => {
      if (
        contentCategory.id === payload.contentCategoryId &&
        locale === payload.locale
      ) {
        return {
          ...contentCategory,
          localizations: { [payload.locale]: payload },
        };
      }
      return contentCategory;
    });
  },
  setOptionalFeature(state, payload) {
    state.optionalFeatures = payload;
  },
  setEditedContentTypeOptionalFeatures(state, payload) {
    state.editedContentTypeOptionalFeatures = payload;
  },
  addSelectOptionalFeature(state, payload) {
    let object = state.postFiles.find((a) => a.id == payload.id);
    object.contentTypeOptionalFeature = payload.contentTypeOptionalFeature;
  },
  setOrderNumber(state, payload) {
    for (let item in payload) {
      let contentType = state.contentTypes.find(
        (a) => a.id == payload[item].id
      );
      contentType.orderNumber = payload[item].orderNumber;
    }
  },
  setContentCategoryOrderNumber(state, payload) {
    for (let item in payload) {
      let contentCategory = state.contentCategories.find(
        (a) => a.id == payload[item].id
      );
      contentCategory.orderNumber = payload[item].orderNumber;
    }
    state.contentCategories = state.contentCategories.sort(function(a, b) {
      return a.orderNumber - b.orderNumber;
    });
  },
  setLookupType(state, payload) {
    state.lookupTypes = payload;
  },
  setLookupData(state, payload) {
    state.lookupData = payload;
  },
  createLookupType(state, payload) {
    state.lookupTypes.push(payload);
  },
  setCacheConfigs(state, payload) {
    state.cacheConfigs = payload;
  },
  setCachePurgeStatus(state, payload) {
    state.cachePurgeStatus = payload;
  },
  setDigitalMrketingConfig(state,payload){
    state.digitalMarketingConfig = payload;
  }
};
