import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";
export default {
  async loadPostsFilesClicks(context) {
    await NetworkManager.get(
      context,
      `post-file-click`,
      "PostFileClick",
      "setPostsFilesClicks",
      Parser
    );
  },
  async updatePostFileClickName(context , payload) {
    console.log("payload from action" , payload)
    await NetworkManager.put(
      context,
      `post-file-click/${payload.id}?name=${payload.name}`,
      null,
      "PostFileClick",
      null,
      Parser
    );
  },
};
